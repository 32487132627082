import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import PaymentIcon from '@mui/icons-material/Payment'
import SettingsIcon from '@mui/icons-material/Settings'
import { Grid } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStrings } from '../../assets/localization/strings'
import rootStore from '../../stores/rootStore'
import BrandSettings from './BrandSettings'
import Invoices from './Invoices'
import PaymentSettings from './PaymentSettings'
import TabNavigation from '../common/TabNavigation'
import UserSettings from './UserSettings'
interface Params {
  site: SettingsTab
  brandId: string
}

enum SettingsTab {
  Brand = 'brand',
  Payment = 'payment',
  Invoices = 'invoices',
  Users = 'users'
}
const BrandSettingsWrap = observer(() => {
  const params = useParams<Params>()
  const strings = useStrings()
  const history = useHistory()
  const [selected, setSelected] = useState<SettingsTab>(SettingsTab.Brand)

  const tabs = [
    {
      value: SettingsTab.Brand,
      label: strings.brand_settings_nav_settings,
      icon: <SettingsIcon />,
      component: <BrandSettings />
    },
    {
      value: SettingsTab.Payment,
      label: strings.brand_settings_nav_payment,
      icon: <PaymentIcon />,
      component: <PaymentSettings />
    },
    {
      value: SettingsTab.Invoices,
      label: strings.brand_settings_nav_invoices,
      icon: <DescriptionOutlinedIcon />,
      component: <Invoices />
    },
    {
      value: SettingsTab.Users,
      label: strings.brand_settings_nav_users,
      icon: <GroupOutlinedIcon />,
      component: <UserSettings />
    }
  ]

  useEffect(() => {
    const onMount = async () => {
      const { brandId, site } = params
      if (brandId !== undefined) {
        try {
          await rootStore.brandStore.setSelectedBrand(brandId)
        } catch (e) {
          console.error(e)
        }
      }
      if (Object.values(SettingsTab).includes(site)) {
        setSelected(site)
      } else {
        setSelected(SettingsTab.Brand)
      }
    }
    onMount()
  }, [])

  const setTab = (id: string) => {
    setSelected(id as SettingsTab)
    history.push(`/${params.brandId}/settings/${id}`)
  }

  return (
    <>
      <Grid container mt={2}>
        <Grid item xs={12}>
          <TabNavigation tabs={tabs} selectedTab={selected} setTab={setTab} />
        </Grid>
        {selected === SettingsTab.Brand && <BrandSettings />}
        {selected === SettingsTab.Payment && <PaymentSettings />}
        {selected === SettingsTab.Invoices && <Invoices />}
        {selected === SettingsTab.Users && <UserSettings />}
      </Grid>
    </>
  )
})

export default BrandSettingsWrap
