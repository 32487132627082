import { Grid } from '@mui/material'
import React from 'react'
import NoParticipants from '../../assets/illustrations/im_no-participants.png'
import { useStrings } from '../../assets/localization/strings'
import styles from '../../style/participants.module.css'

const NotAuth = () => {
  const strings = useStrings()
  return (
    <Grid item xs={12} className={styles.notAuth}>
      <img className={styles.noParticipants} src={NoParticipants} alt='sadface.jpg' />
      <h2>{strings.no_auth_title}</h2>
      <span>{strings.no_auth_body}</span>
    </Grid>
  )
}
export default NotAuth
